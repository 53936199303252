import React from 'react'
import { connect } from 'react-redux'
import Layout from '../components/layout'
import SEO from '../components/seo'
import TermsOfServiceTicker from '../components/TermsOfServiceTicker'
import CookieNotice from '../components/shared/CookieNotice'

const TermsOfServicePage = ({ declarePageAsNotLanding }) => {
  declarePageAsNotLanding()
  return (
    <Layout>
      <SEO title='Terms of Service' />
      <CookieNotice />
      <h1>Terms of Service | Game-changing esports analytics - Shadow</h1>
      <TermsOfServiceTicker />
    </Layout>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    declarePageAsNotLanding: () => dispatch({ type: `SET_LANDING_PAGE_FALSE` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsOfServicePage)
