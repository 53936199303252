import React from 'react'
import classnames from 'classnames'
import * as styles from './styles.module.scss'

const TermsOfServiceTicker = () => (
  <div
    className={classnames(
      styles.privacyPolicyWrapper,
      'col-centered',
      '--narrow'
    )}
  >
    <p>
      Shadow Esports GmbH (“Shadow”) operates the Shadow Scoreboard, a widget,
      visualizing the current score of an esports match (“Scoreboard”). Shadow
      provides the Shadow-branded Scoreboard free of charge to support the fan
      experiences and to enable long-term growth for the wider esports
      community.
      <br />
      <br />
      The use of the Scoreboard is subject to the following terms (“Terms of
      Use” or “ToU”), made exclusively between Shadow and the Customer. By using
      the Scoreboard, the Customer Agrees to be bound by these ToU.
    </p>
    <h2 className={styles.h2}>Definitions</h2>
    <p>
      For the purposes of these Terms of Use
      <br />
      <br />
      <ul>
        <li>
          API means an application programming interface providing access to the
          Data;
        </li>
        <li>
          Shadow Trademarks means any registered trademarks as well as
          registered or unregistered company names held by Shadow;
        </li>
        <li>
          Credentials means information and/or tools for verification and
          authentication, including but not limited to login data and tokens
          that allow the Customer to access and use the Scoreboard;
        </li>
        <li>Data means any content provided by Shadow under the Scoreboard;</li>
        <li>
          Support means customer care and troubleshooting services via a
          ticketing system on shadow.gg.
        </li>
        <li>
          Customer means any individual or organization implementing the
          Scoreboard into a website;
        </li>
        <li>
          Updates means the actualization of the LDP to a new version that may
          include non-significant improvements;
        </li>
        <li>
          Upgrades means any significant improvement of the Scoreboard parts
          that are not part of an Update;
        </li>
        <li>
          Widget means a java script provided by Shadow, integrated in
          Customer's system, providing the Scoreboard.
        </li>
      </ul>
    </p>
    <h2 className={styles.h2}>Ownership</h2>
    <p>
      Any right, title and/or interest in the Scoreboard, Widget or Shadow
      Trademarks and any parts thereof are and remain the sole property of
      Shadow. Customeris granted the right to use the Scoreboard and the Data to
      the extent specified in these ToU.
    </p>
    <h2 className={styles.h2}>Provision of Access and Use</h2>
    <p>
      <ol type='a'>
        <li>
          Shadow provides a download link to access the Widget and information
          on the integration of the Widget into a website.
        </li>
        <li>
          Customer integrates the Widget according to the information provided
          by Shadow. Customer is solely responsible for fulfilling the system
          requirements required for the access and use of the Widget.
        </li>
      </ol>
    </p>
    <h2 className={styles.h2}>Provisions of Access and Use</h2>
    <p>
      Shadow grants Customer a non-exclusive, worldwide, revocable,
      non-sublicensable and non-transferable right to access and use the Widget
      and the Data subject to the conditions that the Customer's website and/or
      offer
      <br />
      <br />
      <ul>
        <li>complies with all applicable laws,</li>
        <li>does not violate any third party rights and</li>
        <li>
          does not contain any explicit content or otherwise improper content.
        </li>
      </ul>
    </p>
    <h2 className={styles.h2}>Rights of Shadow</h2>
    <p>
      <ol type='a'>
        <li>
          Shadow has the right and the Customer accepts advertisement activities
          within the Scoreboard.
        </li>
        <li>
          Shadow has the right to provide Data in its sole discretion. The
          Customer has no right to a specific content or coverage.
        </li>
      </ol>
    </p>
    <h2 className={styles.h2}>Obligations of Customers and Restrictions</h2>
    <p>
      <ol type='a'>
        <li>
          Any use for any purpose other than granted hereunder is prohibited
          unless approved by Shadow in writing.
        </li>
        <li>
          Customers shall not use the Widget, Scoreboard, Data or other
          information provided by Shadow to develop and/or create a product with
          the same or substantially the same functionalities as the Widget or
          Scoreboard.
        </li>
        <li>
          Customers shall not copy, translate, modify, decompile, disassemble or
          reverse engineer the Widget or Scoreboard or otherwise derive or
          attempt to derive the source code and/or algorithms, unless permitted
          by mandatory law.
        </li>
        <li>
          Customers shall not remove, circumvent, reverse engineer, decrypt, or
          otherwise alter or interfere with digital rights management or copy
          protection features associated with the Widget or Scoreboard or any
          other technologies used to control the access to the Widget or
          Scoreboard.
        </li>
        <li>
          Customers are not entitled to share the Credentials with Customers who
          are not authorized to use the Widget.
        </li>
      </ol>
    </p>
    <h2 className={styles.h2}>Violation of Obligations and Restrictions</h2>
    <p>
      <ol type='a'>
        <li>
          In the event the Customer violates any of their obligations or
          restrictions under these ToU (“Violation(s)”), the Customer shall pay
          to Shadow (i) any and all amounts obtained as a result of the
          Violation(s) and (ii) for each month during which the Violation(s)
          occur(s), a contractual penalty, the amount of which shall be
          determined at the reasonable discretion of Shadow, taking into account
          the nature and extent of the Violation and the degree of misconduct,
          and which in case of a conflict shall be subject to judicial review by
          the competent authority.
        </li>
        <li>
          Furthermore, in the event of any Violation(s), Shadow is entitled to
          suspend the service under these ToU with immediate effect and/or to
          terminate this agreement with immediate effect and without prejudice
          to any other rights or remedies available under these ToU or
          applicable law.
        </li>
      </ol>
    </p>
    <h2 className={styles.h2}>Third Party Licence Obligations</h2>
    <p>
      The Widget may include third party software, which is provided by their
      authors under separate licence terms. If the Widget includes or
      incorporates such third party components, the respective third party’s
      licence terms will be made available online as indicated by Shadow.
    </p>
    <h2 className={styles.h2}>Support</h2>
    <p>
      Shadow provides Support via the ticketing system on shadow.gg. Shadow may
      provide priority support services upon separate agreement.
    </p>
    <h2 className={styles.h2}>Cookies, Updates and Upgrades</h2>
    <p>
      <ol type='a'>
        <li>
          Shadow uses tracking cookies within the Widget to control and develop
          the performance of its services.
        </li>
        <li>
          Shadow is not obliged to provide any Upgrades to the Scoreboard or
          Widget
        </li>
        <li>
          With the exception of the warranty owed by law or according to these
          ToU, Shadow is not obliged to provide Updates.
        </li>
      </ol>
    </p>
    <h2 className={styles.h2}>Warranty for Material and Legal Defects</h2>
    <p>
      <ol>
        <li>
          Shadow does not make or give any representation, warranty or
          undertaking as to the accuracy, completeness, availability,
          reliability, timeliness, or quality of the Widget, Scoreboard or Data
          or as to their suitability for a particular purpose or as to the title
          and non-infringement of third-party rights.
        </li>
        <li>
          Shadow is entitled to remedy defects at its discretion by providing a
          new release or by modifications, provided that the scope of the
          services is not changed more than insignificantly.
        </li>
        <li>
          A no-fault based liability for defects existing at the time of
          contracting is excluded, unless the defect relates to a feature of the
          Widget essential for the ToU (Section 536a (1) German Civil Code
          (BGB)).
        </li>
      </ol>
    </p>
    <h2 className={styles.h2}>Termination</h2>
    <p>
      Shadow has the right to terminate the ToU with one (1) week prior notice.
      The statutory right of termination for cause remains unaffected.
    </p>
    <h2 className={styles.h2}>Liability</h2>
    <p>
      <ol type='a'>
        <li>
          Shadow is only liable for damages resulting from an intentional or
          grossly negligent breach of duty. In the event of simple negligence,
          Shadow is only liable in the event of injury to life, body, health or
          an essential contractual obligation. Essential contractual obligations
          are those whose fulfilment is necessary for the achievement of the
          objective of the contract, or which enable the performance of the
          contract in the first place. In the event of a slightly negligent
          breach of essential contractual obligations, Shadow is liable up to
          the amount of the damage typical of the contract and foreseeable at
          the time of conclusion of the contract.
        </li>
        <li>
          The above limitations of liability shall also apply in favour of
          Shadow's legal representatives and agents. The liability according to
          the Product Liability Act remains unaffected.
        </li>
      </ol>
    </p>
    <h2 className={styles.h2}>Termination</h2>
    <p>
      <ol type='a'>
        <li>
          Changes or additions to these ToU must be made in writing. This also
          applies to the written form requirement itself.
        </li>
        <li>
          Customers shall not assign any of its rights or obligations under
          these ToU to any third party without the prior written consent of
          Shadow.
        </li>
        <li>
          The law of the Federal Republic of Germany applies, with the exclusion
          of the United Nations Convention on Contracts for the International
          Sale of Goods (UNCITRAL/CISG). The place of jurisdiction for all
          disputes arising from the contractual relationship is Berlin, Germany.
        </li>
        <li>
          Shadow is entitled to change the regulations in these ToU. An
          amendment shall become effective upon receipt by the Customer if the
          Customer has not objected in writing within six (6) weeks after
          receipt of the amendment. This shall only apply if Shadow has informed
          the Customer in the amendment notification of the possibility of
          objection and the consequences of a failure to object. Changes to the
          essential elements of the contract agreed with Shadow shall require
          the consent of the Customer irrespective of this provision. If
          Customer does not agree to the changes, Shadow shall be entitled to
          immediately terminate the contract.
        </li>
      </ol>
    </p>
  </div>
)

export default TermsOfServiceTicker
